body.modal-open {
    overflow: visible;
}

#template-view{
    padding-top: 30px;
}

#template-view .template-block {
    border-radius: 4px;
    border: 3px dashed #FFA500;
    min-height:70px;
    overflow: hidden;
    padding: 20px 10px;
    position: relative;
    width: 100%;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

#template-view .template-block:hover {
    background: #EDEFF4;
    border-color:rgba(0,0,0,0.8);
}

#template-view .template-block .block-content {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

#template-view .template-block:hover .block-content {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

#template-view .template-block:hover .shadow .btn {
    top:calc(50% - 25px);
}
#template-view .template-block .shadow .btn {
    border: 1px solid;
    position: absolute;
    top:-50%;
    /*top:calc(50% - 22px);*/
    left: calc(50% - 110px);
    font-size: 14px;
    max-width: 80%;
    width: 220px;
}

#template-view .template-block .shadow .block-clear {
    background: rgb(255, 208, 56);
    border-color: #FFA500;
    color: #4d4e50;
}

#template-view .template-block .shadow .block-clear:hover {
    background: rgb(255, 182, 78);
    border-color: #ff8c05;
}

#template-view .template-block .shadow .block-config {
    background: rgb(109, 173, 255);
    border-color: #066eff;
    color: #fff;
}

#template-view .template-block .shadow .block-config:hover {
    background: rgb(91, 141, 255);
    border-color: #075fff;
}

#template-view .template-block:hover .shadow {
    /*box-shadow: 0 0 60px 200px rgba(0,0,0,0.8) inset;*/
}

#template-view .template-block.block-defined, .template-block.block-large.block-defined {
    border: none;
    min-height:initial !important;
    padding: 0;
}

#template-view .template-block h3 {
    line-height:70px;
    margin:0;
    text-align: center;
}

#template-view .template-block.block-large .unconfigured {
    margin: 40px 0;
}
#template-view .template-block.block-large .unconfigured span {
    display: inline-block;
    padding: 0 40px;
    width: 100%;
}

.template-block.block-large {
    min-height:377.333px !important;
}

#template-view .template-block:hover.block-defined .shadow {
    border: 3px dashed rgba(0,0,0,0.8);
    border-radius: 4px;
}

.shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 60px -100px rgba(0,0,0,0.8) inset;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    z-index:1;
}

#template-view .posts__item--card, .posts__item--card:last-child {
    margin-bottom: 0;
}

/* Pré Loader ================== */
#template-view .select2-container {
    width: 100% !important;
}
#template-view .select2-container--default .select2-selection--single {
    border:1px solid #e4e7ed;
    border-radius: 2px;
    height: 45px;
    padding: 0 40px 0 10px;
}
#template-view .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #9a9da2;
    line-height: 45px;
}
#template-view .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 45px;
    right: 10px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border:1px solid #cdd0d6 !important;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #0CB2E2 !important;
}
.select2-container--default .select2-results__option {
    padding: 8px 15px;
}

/* =========================== */

/* Pré Loader ================== */
#preloader {
    background: #1c1f26;
    color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1051;
}

#preloader .flat-spin {
    left: calc(50% - 60px);
    position: absolute;
    top: calc(50% - 40px);
}

#preloader .flat-spin i:before {
    font-size: 100px;
}
.flat-spin i:before{
    animation-duration: 2500ms;
    -moz-animation-duration: 2500ms;
    -webkit-animation-duration: 2500ms;
    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-name: spin;
    -moz-animation-name: spin;
    -webkit-animation-name: spin;

    animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    display: inline-block;
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/* =========================== */

.ui-widget.ui-widget-content {
    z-index: 10 !important;
}