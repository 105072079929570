/* iCheck plugin Line skin, orange
----------------------------------- */
.icheckbox_line-orange,
.iradio_line-orange {
    position: relative;
    display: block;
    margin: 0;
    padding: 5px 15px 5px 38px;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    background: #f70;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
}
    .icheckbox_line-orange .icheck_line-icon,
    .iradio_line-orange .icheck_line-icon {
        position: absolute;
        top: 50%;
        left: 13px;
        width: 13px;
        height: 11px;
        margin: -5px 0 0 0;
        padding: 0;
        overflow: hidden;
        background: url(line.png) no-repeat;
        border: none;
    }
    .icheckbox_line-orange.hover,
    .icheckbox_line-orange.checked.hover,
    .iradio_line-orange.hover {
        background: #FF9233;
    }
    .icheckbox_line-orange.checked,
    .iradio_line-orange.checked {
        background: #f70;
    }
        .icheckbox_line-orange.checked .icheck_line-icon,
        .iradio_line-orange.checked .icheck_line-icon {
            background-position: -15px 0;
        }
    .icheckbox_line-orange.disabled,
    .iradio_line-orange.disabled {
        background: #FFD6B3;
        cursor: default;
    }
        .icheckbox_line-orange.disabled .icheck_line-icon,
        .iradio_line-orange.disabled .icheck_line-icon {
            background-position: -30px 0;
        }
    .icheckbox_line-orange.checked.disabled,
    .iradio_line-orange.checked.disabled {
        background: #FFD6B3;
    }
        .icheckbox_line-orange.checked.disabled .icheck_line-icon,
        .iradio_line-orange.checked.disabled .icheck_line-icon {
            background-position: -45px 0;
        }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 3/2),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_line-orange .icheck_line-icon,
    .iradio_line-orange .icheck_line-icon {
        background-image: url(line@2x.png);
        -webkit-background-size: 60px 13px;
        background-size: 60px 13px;
    }
}