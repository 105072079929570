.icheckbox_line-orange, .iradio_line-orange {
    padding: 9px 15px 8px 38px;
}

.icheckbox_line-orange, .iradio_line-orange {
    opacity: 0.6;
}

.icheckbox_line-orange.checked, .iradio_line-orange.checked {
    opacity: 1;
}